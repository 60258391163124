import React, { FC } from "react"
import Layout from "../components/layout"
import { useSiteMetadata } from "../hooks/site"

interface ContactProps {
  street: string
  plz: string
  town: string
  email: string
  phone: string
  fax: string
}

const ContactInformation: FC<ContactProps> = ({
  street,
  plz,
  town,
  email,
  phone,
  fax,
}) => {
  return (
    <>
      <p>
        Orchestergemeinschaft München-Andechs e. V.
        <br />
        {street}
        <br />
        {plz} {town}
      </p>
      <p>
        E-Mail: {email}
        <br />
        Tel: {phone}
        <br />
        Fax: {fax}
      </p>
    </>
  )
}

const Vorstand: FC<{ names: string[] }> = ({ names }) => {
  const keyForName = (name: string) =>
    name.trim().replace(" ", "").replace(".", "-").toLowerCase()

  return (
    <>
      <h3>Vertretungsberechtigter Vorstand</h3>
      <ul>
        {names.map(name => (
          <li key={keyForName(name)}>{name}</li>
        ))}
      </ul>
    </>
  )
}

const Register: FC<{ court: string; number: string }> = ({ court, number }) => {
  return (
    <p>
      Registergericht: {court}
      <br />
      Registernummer: {number}
    </p>
  )
}

const InhaltlichVerantwortlicher: FC<{
  name: string
  street: string
  plz: string
  town: string
}> = ({ name, street, plz, town }) => {
  return (
    <p>
      Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:
      <br />
      {name}
      <br />
      {street}
      <br />
      {plz} {town}
    </p>
  )
}

const Impressum: FC = () => {
  const { impressum } = useSiteMetadata()

  const { street, plz, town, email, phone, fax } = impressum.contact

  return (
    <Layout>
      <h1>Impressum</h1>
      <h3>Betreiber der Webseite</h3>
      <ContactInformation
        street={street}
        plz={plz}
        town={town}
        email={email}
        phone={phone}
        fax={fax}
      />
      <Vorstand names={impressum.vorstand} />
      <Register
        court={impressum.registergericht}
        number={impressum.registernummer}
      />
      <InhaltlichVerantwortlicher
        name={impressum.inhaltlichVerantwortlicher}
        street={street}
        plz={plz}
        town={town}
      />
    </Layout>
  )
}

export default Impressum
